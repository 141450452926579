import { lazy } from 'react';

const listOfPreampAssets = {
  ['300 Offer Hero']: lazy(() => import('./assets/300 Offer Hero')),
  ['Business - Banner - MiR - V2']: lazy(() =>
    import('./assets/Business - Banner - MiR - V2')
  ),
  ['Business - Banner - MiR - V3']: lazy(() =>
    import('./assets/Business - Banner - MiR - V3')
  ),
  ['Home - ACP Banner - HN Redesign V1']: lazy(() =>
    import('./assets/Home - ACP Banner - HN Redesign V1')
  ),
  ['Home - ACP Banner - Rebrand V1']: lazy(() =>
    import('./assets/Home - ACP Banner - Rebrand V1')
  ),
  ['Home - Competitor Comparison Rebrand V1']: lazy(() =>
    import('./assets/Home - Competitor Comparison Rebrand V1')
  ),
  ['Home - Competitor Comparison Rebrand V2']: lazy(() =>
    import('./assets/Home - Competitor Comparison Rebrand V2')
  ),
  ['Home - Competitor MiR - HN Redesign V1']: lazy(() =>
    import('./assets/Home - Competitor MiR - HN Redesign V1')
  ),
  ['Home - Education Module - Rebrand V1']: lazy(() =>
    import('./assets/Home - Education Module - Rebrand V1')
  ),
  ['Home - Education Module - Redesign V1']: lazy(() =>
    import('./assets/Home - Education Module - Redesign V1')
  ),
  ['Home - FAQs - HN Redesign V1']: lazy(() =>
    import('./assets/Home - FAQs - HN Redesign V1')
  ),
  ['Home - Final EPQ - HN Redesign V1']: lazy(() =>
    import('./assets/Home - Final EPQ - HN Redesign V1')
  ),
  ['Home - Final EPQ - Rebrand V1']: lazy(() =>
    import('./assets/Home - Final EPQ - Rebrand V1')
  ),
  ['Home - Footer - HN Redesign V1']: lazy(() =>
    import('./assets/Home - Footer - HN Redesign V1')
  ),
  ['Home - Footer - Rebrand V1']: lazy(() =>
    import('./assets/Home - Footer - Rebrand V1')
  ),
  ['Home - Hero - Pre-EPQ - Editable']: lazy(() =>
    import('./assets/Home - Hero - Pre-EPQ - Editable')
  ),
  ['Home - Hero - Presidents Day 2022']: lazy(() =>
    import('./assets/Home - Hero - Presidents Day 2022')
  ),
  ['Home - Hero MiR - HN Redesign V1']: lazy(() =>
    import('./assets/Home - Hero MiR - HN Redesign V1')
  ),
  ['Home - Hero MiR - Rebrand V1']: lazy(() =>
    import('./assets/Home - Hero MiR - Rebrand V1')
  ),
  ['Home - Hero RTB - Redesign']: lazy(() =>
    import('./assets/Home - Hero RTB - Redesign')
  ),
  ['Home - Jupiter Banner']: lazy(() =>
    import('./assets/Home - Jupiter Banner')
  ),
  ['Home - Modal - HN Redesign V1']: lazy(() =>
    import('./assets/Home - Modal - HN Redesign V1')
  ),
  ['Home - Modal - ReturnVisitor Rebrand V1']: lazy(() =>
    import('./assets/Home - Modal - ReturnVisitor Rebrand V1')
  ),
  ['Home - Nav Header - HN Redesign V1']: lazy(() =>
    import('./assets/Home - Nav Header - HN Redesign V1')
  ),
  ['Home - Nav Header - Rebrand V1']: lazy(() =>
    import('./assets/Home - Nav Header - Rebrand V1')
  ),
  ['Home - Rural US News']: lazy(() => import('./assets/Home - Rural US News')),
  ['Home - US News - Rebrand V1']: lazy(() =>
    import('./assets/Home - US News - Rebrand V1')
  ),
  ['Home - US News - V2']: lazy(() => import('./assets/Home - US News - V2')),
  ['Home - Value Props - HN Redesign V1']: lazy(() =>
    import('./assets/Home - Value Props - HN Redesign V1')
  ),
  ['Home - Value Props - Rebrand V1']: lazy(() =>
    import('./assets/Home - Value Props - Rebrand V1')
  ),
  ['Home - Whole Home WiFi - Rebrand V1']: lazy(() =>
    import('./assets/Home - Whole Home WiFi - Rebrand V1')
  ),
  ['VRC Hero - V2']: lazy(() => import('./assets/VRC Hero - V2')),
};

export default listOfPreampAssets;
